import {
    hidden,
    visible,
    getDataByCodePostal,
    newInput,
    texteForNatureActe,
    texteForPresident,
    texteForDirecteurGeneral,
    texteForCapital, texteForCessionTransmissionAction, texteForAdmissionAssemble, visibility, cssVal
} from "../../helpers";
import {def} from "../../dictionnaire";


export const sa = {
    title: "SA Société Anonyme",
    texte: "{{acteGroup}} a été constituée la société suivante : <strong>Forme</strong> : {{societyGroup.societeFormeJuridique}} <strong>Dénomination : {{societyGroup.societeDenomination}}</strong>[[ <strong>Sigle</strong> : {{societyGroup.sigle}}]]. <strong>Siège</strong> : {{siegeSocialGroup.societeAdresse}} [[{{siegeSocialGroup.societeAdresse2}}]] {{siegeSocialGroup.societeCodePostal}} {{siegeSocialGroup.societeVille}}. <strong>Objet</strong> : {{societyGroup.objet}}. <strong>Durée</strong> : {{societyGroup.duree}} ans à compter de <strong>l'immatriculation au Rcs</strong> {{siegeSocialGroup.societeRcsVille}}. <strong>Capital</strong> : {{societyGroup.typeCapital}}. <strong>Président du conseil d'administration</strong> : {{presidentGroup}}. [[ <strong>Directeur général</strong> : {{directeurgeneralGroup}}.]] <strong>Administrateurs</strong> : {{administrateurGroup.administrateur}} [[ <strong>Commissaires aux comptes : Titulaire</strong> : {{commissaireAuxComptesGroup.indiquez_1}} [[<strong>Suppléant</strong> : {{commissaireAuxComptesGroup.indiquez_2}}]]]]. <strong>Admission aux assemblées</strong> : {{admissionAssembleGroup}}. <strong>Cession et transmission des actions</strong> : {{cessionTransmissionActionGroup}}.",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "acteGroup":
                tmpText = texteForNatureActe(varText);
                break;
            case "presidentGroup":
                tmpText = texteForPresident(varText);
                break;
            case "societyGroup.typeCapital":
                if(varText['societyGroup.typeCapital'] === 'variable') {
                    tmpText = `variable, initial : ${cssVal(varText["societyGroup.societeCapitalInitial"])} €, minimum : ${cssVal(varText["societyGroup.capitalMinimum"])} €`;
                } else {
                    tmpText = ` ${cssVal(varText["societyGroup.societeCapital"])} €`
                }
                break;
            case "societyGroup.montantCapital":
                tmpText = texteForCapital(varText);
                break;
            case "directeurgeneralGroup":
                tmpText = texteForDirecteurGeneral(varText);
                break;
            case "admissionAssembleGroup":
                tmpText = texteForAdmissionAssemble(varText);
                break;
            case "cessionTransmissionActionGroup":
                tmpText = texteForCessionTransmissionAction(varText);
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        siegeSocialGroup: [{}],
        gerancesGroup: [{}],
        cogerancesGroup: [{}],
    },
    schema: [
        {
            //0
            name: "acteGroup",
            label: "Les statuts",
            type: "group",
            value: [{}],
            children: [
                newInput(def.natureActe, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            sa.schema[0].children[3] = visibility(sa.schema[0].children[3], val, "acte authentique");
                        }
                    }
                }),
                newInput(def.dateActe, '_required'),
                newInput(def.lieuActe, '_required'),
                newInput(def.recuPar, '_required', {}, 'hidden'),
            ],
        },
        {
            //1
            name: "societyGroup",
            label: "La société",
            type: "group",
            value: [{}],
            children: [
                newInput(def.formeSocieteSa, '_required', {name: "societeFormeJuridique"}),
                newInput(def.denomination, '_required', {name: "societeDenomination"}),
                newInput(def.sigle),
                newInput(def.objet, '_required'),
                newInput(def.duree, '_required'),
                newInput(def.typeCapital, '_required', {
                    on: {
                        input: async (val) => {
                            sa.schema[1].children[6] = val === "fixe" ? visible(sa.schema[1].children[6]) : hidden(sa.schema[1].children[6]);
                            sa.schema[1].children[7] = val === "variable" ? visible(sa.schema[1].children[7]) : hidden(sa.schema[1].children[7]);
                            sa.schema[1].children[8] = val === "variable" ? visible(sa.schema[1].children[8]) : hidden(sa.schema[1].children[8]);
                        }
                    }
                }),
                newInput(def.montantCapital, '_required', {name: 'societeCapital'}),
                newInput(def.capitalInitial, '_required', {name: 'societeCapitalInitial'}, 'hidden'),
                newInput(def.capitalMinimum, '_required', {}, 'hidden'),
            ]
        },
        {
            //2
            name: "siegeSocialGroup",
            label: "Adresse du siège social",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required', {name: "societeAdresse"}),
                newInput(def.adresseSuite, '_required', {name: "societeAdresse2"}),
                newInput(def.codePostal, '_required', {
                    name: "societeCodePostal",
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            sa.schema[2].children[3].options = data.options;
                            sa.model.siegeSocialGroup[0].societeVille = data.ville_slug;
                            sa.model.siegeSocialGroup[0].societeRcsVille = data.greffe;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect', {name: "societeVille"}),
                newInput(def.lieuImmatriculation, '_required', {name: "societeRcsVille"}, 'disabled'),
            ]
        },
        {
            //3
            name: "presidentGroup",
            label: "Président",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            sa.schema[3].children[5].options = data.options;
                            sa.model.presidentGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_required')
            ]
        },
        {
            //4
            name: "directeurgeneralGroup",
            label: "Directeur général",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nominationDirecteurGeneral, '', {
                    on: {
                        input: (val) => {
                            sa.schema[4].children[1] = val ? visible(sa.schema[4].children[1]) : hidden(sa.schema[4].children[1]);
                            sa.schema[4].children[2] = val ? visible(sa.schema[4].children[2]) : hidden(sa.schema[4].children[2]);
                            sa.schema[4].children[3] = val ? visible(sa.schema[4].children[3]) : hidden(sa.schema[4].children[3]);
                            sa.schema[4].children[4] = val ? visible(sa.schema[4].children[4]) : hidden(sa.schema[4].children[4]);
                            sa.schema[4].children[5] = val ? visible(sa.schema[4].children[5]) : hidden(sa.schema[4].children[5]);
                            sa.schema[4].children[6] = val ? visible(sa.schema[4].children[6]) : hidden(sa.schema[4].children[6]);
                        }
                    }
                }),
                newInput(def.nom, '_required', {}, 'hidden'),
                newInput(def.prenom, '_required', {}, 'hidden'),
                newInput(def.adresse, '_required', {}, 'hidden'),
                newInput(def.adresseSuite, '', {}, 'hidden'),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if(Object.keys(data).length === 0) {
                                return false;
                            }

                            sa.schema[4].children[6].options = data.options;
                            sa.model.directeurgeneralGroup[0].ville = data.ville_slug;
                        }
                    }
                }, 'hidden'),
                newInput(def.ville, '_required', {}, 'hidden')
            ]
        },
        {
            //5
            name: "administrateurGroup",
            label: "Administrateurs",
            type: "group",
            value: [{}],
            children: [
                newInput(def.administrateur, '_required'),
            ]
        },
        {
            //6
            name: "commissaireAuxComptesGroup",
            label: "Commissaires aux comptes",
            type: "group",
            value: [{}],
            children: [
                newInput(def.nominationCommissaireAuxComptes, '', {
                    on: {
                        input: (val) => {
                            sa.schema[6].children[1] = val ? visible(sa.schema[6].children[1]) : hidden(sa.schema[6].children[1]);
                            sa.schema[6].children[2] = val ? visible(sa.schema[6].children[2]) : hidden(sa.schema[6].children[2]);
                            sa.schema[6].children[3] = val ? visible(sa.schema[6].children[3]) : hidden(sa.schema[6].children[3]);
                            sa.schema[6].children[4] = val ? visible(sa.schema[6].children[4]) : hidden(sa.schema[6].children[4]);
                        }
                    }
                }),
                newInput(def.typeTitulaire, '_required', {
                    on: {
                        input: (val) => {
                            sa.schema[6].children[2].label = val === 'physique' ? 'Indiquez : nom, prénom, adresse, cp, ville' : 'Indiquez : forme, dénomination, capital, siège(adresse, cp, ville), RCS(ville et N°)';
                        }
                    }
                }, 'hidden'),
                newInput(def.indiquez_1, '_required', {}, 'hidden'),
                newInput(def.typeSuppleant, '_required', {
                    on: {
                        input: (val) => {
                            sa.schema[6].children[4] = val === 'aucun' ? hidden(sa.schema[6].children[4]) : visible(sa.schema[6].children[4]);
                            sa.schema[6].children[4].label = val === 'physique' ? 'Indiquez : nom, prénom, adresse, cp, ville' :
                                val === 'moral' ?  'Indiquez : forme, dénomination, capital, siège(adresse, cp, ville), RCS(ville et N°)' : '';
                        }
                    }
                }, 'hidden'),
                newInput(def.indiquez_2,'_required', {}, 'hidden')
            ]
        },
        {
            //7
            name: "admissionAssembleGroup",
            label: "Admission aux assemblées",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typeAdmission, '_required', {
                    on: {
                        input: (val) => {
                            sa.schema[7].children[1] = val === 'autre' ? visible(sa.schema[7].children[1]) : hidden(sa.schema[7].children[1]);
                        }
                    }
                }),
                newInput(def.autre, '', {}, 'hidden')
            ]
        },
        {
            //8
            name: "cessionTransmissionActionGroup",
            label: "Cession et transmission des actions",
            type: "group",
            value: [{}],
            children: [
                newInput(def.typeTransmissionAction, '_required', {
                    on: {
                        input: (val) => {
                            sa.schema[8].children[1] = val === 'autre' ? visible(sa.schema[8].children[1]) : hidden(sa.schema[8].children[1]);
                        }
                    }
                }),
                newInput(def.autre, '', {}, 'hidden')
            ]
        }
    ],
};